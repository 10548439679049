/*
 * @Description: 全局路由守卫
 * @Author: zhangguoliang
 * @Date: 2021-08-03 17:02:51
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2021-12-14 16:40:01
 */

import router, { constantRoutes } from '@/router';
import store from '@/store';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { Tag } from './interface';
import { isEmpty } from 'lodash';

NProgress.configure({ showSpinner: false });

const paths = constantRoutes.map((item) => item.path);

function addTags(tag: Tag) {
  const { tags }: { tags: Tag[] } = store.getters;
  const paths: string[] = tags.map((tag) => tag.path);
  if (!paths.includes(tag.path)) {
    store.commit('SET_TAGS', [...tags, tag]);
  } else {
    store.commit(
      'SET_TAGS',
      tags.map((t) => ({
        ...t,
        query: t.path === tag.path ? tag.query : t.query,
      }))
    );
  }
}

// 验证白名单
const whiteList = ['/login', '/changePassword'];

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  document.title = to.meta?.title || '应用服务平台';
  if (!store.getters.token) {
    if (whiteList.includes(to.path)) {
      next();
    } else {
      next({ path: '/login' });
    }
  } else if (isEmpty(store.getters.menus)) {
    await store.dispatch('menus');
    next(to.fullPath); // 重置路由
  } else {
    if (to.path !== '/login') {
      next();
    } else {
      next({ path: '/' });
    }
  }
});

router.afterEach((to) => {
  if (to.name && !paths.includes(to.path)) {
    addTags({ name: to.meta?.title as string, path: to.path, query: to.query });
  }
  NProgress.done();
});
